import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import TreeUtils from '@/common/TreeUtils';
let PermTable = class PermTable extends Vue {
    constructor() {
        super(...arguments);
        this.navIndeterminate = false;
        this.navCheckAll = false;
        this.isInited = false;
        this.timoutId = 0;
    }
    onPermDataChange() {
        this.setNavStatus();
        this.setMenuStatus();
        if (!this.isInited) {
            this.isInited = true;
            clearTimeout(this.timoutId);
            this.timoutId = setTimeout(() => {
                this.setRoleDetail();
            }, 50);
        }
    }
    setRoleDetail() {
        TreeUtils.forEachTree(this.permData, (item) => {
            const detail = this.roleDetailPermData.find(detailItem => detailItem.code === item.code);
            if (detail) {
                item.isChecked = true;
            }
        });
    }
    /**
     * 设置菜单选中状态
     */
    setMenuStatus() {
        this.permData.forEach((item) => {
            if (!item.children.length)
                return;
            const isChecked = item.children.every(itemChild => itemChild.isChecked);
            if (isChecked) {
                item.isChecked = true;
                item.indeterminate = false;
            }
            else {
                const indeterminate = item.children.some(itemChild => itemChild.isChecked);
                item.indeterminate = indeterminate;
                item.isChecked = false;
            }
        });
    }
    /**
     * 设置导航的选中状态
     */
    setNavStatus() {
        if (this.permData.length) {
            const navCheckAll = this.permData.every(item => item.isChecked);
            if (navCheckAll) {
                this.navCheckAll = true;
                this.navIndeterminate = false;
            }
            else {
                const navIndeterminate = this.permData.some(itemChild => itemChild.isChecked || itemChild.indeterminate);
                this.navIndeterminate = navIndeterminate;
                this.navCheckAll = false;
            }
        }
    }
    /**
     * 单选项
     */
    onCheckItemChange(item) {
        // 上联动检查下
        const link1 = {
            'org-department-manage': 'org-user-manage',
            'org-tag-manage-common-manage': 'org-tag-manage-common-user-dept',
            'org-tag-manage-organization-manage': 'org-tag-manage-organization-user-dept',
        };
        // 下联动检查上
        const link2 = {
            'org-user-manage': 'org-department-manage',
            'org-tag-manage-common-user-dept': 'org-tag-manage-common-manage',
            'org-tag-manage-organization-user-dept': 'org-tag-manage-organization-manage',
        };
        if (link1[item.code]) {
            const { nodeState } = TreeUtils.getTreeNodeStateByKey(link1[item.code], 'code', this.permData);
            if (item.isChecked && nodeState && nodeState.isChecked) {
                this.$message.error('不能取消');
                return;
            }
        }
        if (link2[item.code]) {
            const { nodeState } = TreeUtils.getTreeNodeStateByKey(link2[item.code], 'code', this.permData);
            if (!item.isChecked && nodeState) {
                nodeState.isChecked = true;
            }
        }
        item.isChecked = !item.isChecked;
    }
    /**
     * 单击全选菜单
     */
    onCheckAllMenuChange(e) {
        const checked = e.target.checked;
        this.permData.forEach((menu) => {
            if (!menu.children.length) {
                menu.isChecked = checked;
                return;
            }
            menu.children.forEach((item) => {
                item.isChecked = checked;
            });
        });
    }
    /**
     * 单击全选元素
     */
    onCheckAllItemChange(menu) {
        const checked = !menu.isChecked;
        if (!menu.children.length) {
            menu.isChecked = checked;
            return;
        }
        menu.children.forEach((item) => {
            item.isChecked = checked;
        });
    }
};
__decorate([
    Prop({
        default: () => {
            return [];
        },
    })
], PermTable.prototype, "roleDetailPermData", void 0);
__decorate([
    Prop()
], PermTable.prototype, "permData", void 0);
__decorate([
    Watch('permData', { immediate: true, deep: true })
], PermTable.prototype, "onPermDataChange", null);
PermTable = __decorate([
    Component({
        name: 'PermTable',
        components: {},
    })
], PermTable);
export default PermTable;
